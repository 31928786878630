<template>
    <div class="container">
        <div class="right_cont">
            <div class="title flex_center_start_box">
                <div class="title_txt flex_center">
                    <img src="../../assets/img/detail/title_ic3.png" />
                    Company Profile
                </div>
            </div>
            <div class="right_cont_box">
                <div class="group profile">
                    <div class="item">
                        <label class="lab">Co. Name:</label>
                        <div class="txt">{{ obj.coName ? obj.coName : '-' }}</div>
                    </div>
                    <div class="item">
                        <label class="lab">Native Name:</label>
                        <div class="txt">{{ obj.nativeName ? obj.nativeName : '-' }}</div>
                    </div>
                    <div class="item">
                        <label class="lab">Co. ID:</label>
                        <div class="txt">{{ obj.coId ? obj.coId : '-' }}</div>
                    </div>
                    <div class="item">
                        <label class="lab">VAT No:</label>
                        <div class="txt">{{ obj.vatNo ? obj.vatNo : '-' }}</div>
                    </div>
                    <div class="item">
                        <label class="lab">Registry:</label>
                        <div class="txt">{{ obj.registry ? obj.registry : '-' }}</div>
                    </div>
                    <div class="item">
                        <label class="lab">Reg.Capital:</label>
                        <div class="txt">{{ obj.regCapital ? obj.regCapital : '-' }}</div>
                    </div>
                    <div class="item">
                        <label class="lab">Paid Up Capital:</label>
                        <div class="txt">{{ obj.upCapital ? obj.upCapital : '-' }}</div>
                    </div>
                    <div class="item">
                        <label class="lab">Date Founded:</label>
                        <div class="txt">{{ obj.dateFound ? obj.dateFound : '-' }}</div>
                    </div>
                    <div class="item">
                        <label class="lab">Date Incorporated:</label>
                        <div class="txt">{{ obj.dateIncor ? obj.dateIncor : '-' }}</div>
                    </div>
                    <div class="item">
                        <label class="lab">Registered Address:</label>
                        <div v-show="!showTranslate" class="txt">
                            {{ obj.regStreet }}
                            <span v-if="obj.regAddress">,</span>
                            {{ obj.regAddress }}
                            <span v-if="obj.regCountry">,</span>
                            {{ obj.regCountry }}
                        </div>
                        <!-- {{ obj.regAddress ? obj.regAddress : '-' }} -->
                        <div v-show="showTranslate" class="txt">
                            {{ obj.regStreetCn }}
                            <span v-if="obj.regAddressCn">,</span>
                            {{ obj.regAddressCn }}
                            <span v-if="obj.regCountryCn">,</span>
                            {{ obj.regCountryCn }}
                        </div>
                        <!-- {{ obj.regAddressCn ? obj.regAddressCn : '-' }} -->
                    </div>
                    <div class="item">
                        <label class="lab">Operating Address:</label>
                        <div v-show="!showTranslate" class="txt">
                            {{ obj.opeStreet }}
                            <span v-if="obj?.opeAddress">,</span>
                            {{ obj.opeAddress }}
                            <span v-if="obj?.opeCountry">,</span>
                            {{ obj.opeCountry }}
                        </div>
                        <!-- {{ obj.opeAddress ? obj.opeAddress : '-' }} -->
                        <div v-show="showTranslate" class="txt">
                            {{ obj.opeStreetCn }}
                            <span v-if="obj?.opeAddressCn">,</span>
                            {{ obj.opeAddressCn }}
                            <span v-if="obj?.opeCountryCn">,</span>
                            {{ obj.opeCountryCn }}
                        </div>
                        <!-- {{ obj.opeAddressCn ? obj.opeAddressCn : '-' }} -->
                    </div>
                    <div class="item">
                        <label class="lab">City/Country:</label>
                        <div v-show="!showTranslate" class="txt">{{ obj.cityCountry ? obj.cityCountry : '-' }}</div>
                        <div v-show="showTranslate" class="txt">{{ obj.cityCountryCn ? obj.cityCountryCn : '-' }}</div>
                    </div>
                    <div class="item">
                        <label class="lab">Legal Status:</label>
                        <div class="txt">{{ obj.legalStatus ? obj.legalStatus : '-' }}</div>
                    </div>
                    <div class="item">
                        <label class="lab">Listed:</label>
                        <div class="txt">{{ obj.listed ? obj.listed : '-' }}</div>
                    </div>
                    <div class="item">
                        <label class="lab">Last Update:</label>
                        <div class="txt">{{ obj.lastUpdate ? obj.lastUpdate : '-' }}</div>
                    </div>
                    <div class="item">
                        <label class="lab">Data Source:</label>
                        <div class="txt" style="text-decoration: none; color: #1290c9; cursor: pointer" v-for="(item, index) in obj.dataSource" :key="index" @click="setid(item.website)">{{ item.register }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { companyProfile, searchDetailsById } from '@/api/searchApi.js';
import crypto from '../../utils/crypto';
export default {
    data() {
        return {
            obj: {},
            parentTablenum1: [],
        };
    },
    computed: {
        //index传值 是否翻译
        showTranslate: {
            get() {
                // console.log(`this.$parent.indexIsTranslate`, this.$parent.indexIsTranslate);
                return this.$parent.indexIsTranslate;
            },
            set() {},
        },
        curPagePower: {
            get() {
                return JSON.parse(this.$parent.powerToPage);
            },
            set() {},
        },
    },
    methods: {
        setid(row) {
            // console.log(row.Website);
            if (row.includes('http')) {
                let routeData = row;
                window.open(routeData, '_blank');
            } else {
                let routeData = 'http://' + row;
                window.open(routeData, '_blank');
            }
        },
        getdetail() {
            searchDetailsById({ id: this.$route.query.id }).then(res => {
                let nums = [];
                let nums1 = [];
                // this.Relationstable=
                // this.page.total=this.Relationstable.length

                nums = res.data.attributes.person?.identification_details ? res.data.attributes.person?.identification_details : [];
                nums1 = res.data.attributes.watchlist?.identification_details ? res.data.attributes.watchlist?.identification_details : [];
                nums = nums.concat(nums1);

                this.parentTablenum1 = res.data.attributes.entity?.identification_details ? res.data.attributes.entity?.identification_details.concat(nums) : [].concat(nums);

                companyProfile(this.parentTablenum1).then(ress => {
                    let data = JSON.parse(crypto.decrypt(ress));

                    this.obj = data.data;
                });
                // this.tableListnum = res.data.attributes.entity?.identification_details ? res.data.attributes.entity?.identification_details.concat(nums) : [];
            });
        },
    },
    created() {
        // let params = 'id3a=840309097305&companyCountry=US'
        this.getdetail();
    },
};
</script>
<style scoped>
.cont_m {
    margin-top: 30px;
    margin-bottom: 80px;
}
.right_cont {
    margin-top: 60px;
}
.title {
    margin-bottom: 10px;
}
.title_txt {
    font-size: 16px;
    font-family: 'Arial Bold';
}
.title_txt img {
    width: 20px;
    margin-right: 8px;
}
.group.profile {
    padding: 0 10px;
}
.profile .item {
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e7eaed;
}
.profile .item:first-child {
    padding-top: 10px;
}
.profile .item:last-child {
    padding-bottom: 10px;
    border-width: 0;
}
.profile .item .lab {
    width: 148px;
    font-size: 16px;
    color: #8497ab;
}
.profile .item .txt {
    margin-left: 40px;
    color: #022955;
    font-size: 16px;
    width: 1000px;
}
</style>
